import React from 'react'
import './UserInfoCard.scss'
import { IKImage} from "imagekitio-react";
function UserInfoCard(props) {
  return (
    <div className='userInfoCard__container' onClick={(event)=>{
      props.onSelect(event)
    }}>
        <div className='userInfoCard__heading'>
        <div style={{ display: "flex", justifyContent: "center", }}>
        <IKImage
            style={{width:'100%' ,height:'100%', objectFit:"contain",backgroundSize:"contain",backgroundRepeat : "no-repeat"} }
            urlEndpoint="https://ik.imagekit.io/7jhzmda5xn/nextcaller"
            path={props.path}
            width={'100%'}

            height={'100%'}
          />
          </div>
        </div>
        <div className='userInfoCard__content'>
            {props.content}
        </div>
    </div>
  )
}

export default UserInfoCard