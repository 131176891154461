import React from "react";
import "./UserInfo.scss";
import UserInfoCard from "../../components/Card/UserInfoCard";
function UserInfo(props) {
  return (
    <div className="userInfo__container">
      <span
        className="userInfo__cards"
      >
        <UserInfoCard content={"Student"} path={"./Student.png"} onSelect={props.onSelect} />{" "}
      </span>
      <span
        className="userInfo__cards"
      >
        <UserInfoCard content={"Employer"} path={"./Employee.png"} onSelect={props.onSelect}/>{" "}
      </span>
      <span
        className="userInfo__cards"
      >
        <UserInfoCard content={"Entrepreneur"} path={"./Enterpreneur.png"} onSelect={props.onSelect}/>{" "}
      </span>
    </div>
  );
}

export default UserInfo;
