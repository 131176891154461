import React from "react";
import "./Testinomial.css";
function Testinomial(props) {
  return (
    <div className="testinomial__container">
      <div className="testinomial__logo" />

      <div className="testinomial__content">
        <div className="left" />
        <div className="right">
          <div className="content">
            <h3>Neeraj Singh says!</h3>
            <span>
            This is a testimonial section by neeraj singh! here is written what neeraj has to say about his problems before and after installing the next generation Nextcaller app. This is a testimonial section by neeraj singh! here is next generation Nextcaller app.
            </span>
          </div>
        </div>
        <div className="testinomial__button">
        <button onClick={props.next}>Continue</button>
      </div>
      </div>

    </div>
  );
}

export default Testinomial;
