import React from "react";
import "./Hero.css";
import FAQ from "../FAQ/Faq";
import TestinomialCard from "../Card/TestinomialCard";
import PayCard from "../Card/PayCard";
import NextCard from "../Card/NextCard";
import month from '../../assets/image/3month.png'
import year from '../../assets/image/yearly.png'
import sixMonth from '../../assets/image/6month.png'
import { useState } from "react";
function Hero() {

  const [highlighted, sethighlighted] = useState(true);

  return (
    <div className="hero__container">
      <div className="hero__main">
        <div className="hero__logo" />
        <div className="hero__content">
          <div className="hero__text">
            <h1 style={{ fontFamily: "Raleway" }}>Nextcaller</h1>
            <span className="hero__innerContent">
            Evaluating your answers, we calculated that after using{" "}
            Nextcaller, you will successfully overcome your frusturation by
              <span style={{ color: "#4395F5" }}> 93% </span>due to unwanted calls{" "}
             
            </span>
            <div className="hero__button">
              <button>See My Plans</button>
            </div>
          </div>
          <div className="hero__svg" />
        </div>
        <div className="hero__companies">
          <div className="hero__description">
            <span style={{ fontFamily: "Raleway" }}>
            <span style={{ color: "#4395F5" }}>YY% </span> of our users with similar results have sucessfully reduced their{" "}
            </span>
            <span style={{ fontFamily: "Raleway" }}>
              {" "}
              stress and have increased their productivity.
            </span>
          </div>
          <div className="hero__companiesLogo">
            <div className="hero__companyItem1" />
            <div className="hero__companyItem2" />
            <div className="hero__companyItem3" />
          </div>
        </div>

        <div className="hero__stats">
          <div className="hero__statText">
            <span style={{ fontFamily: "Raleway", fontSize: 44 }}>
              Hello <span style={{ color: "#4395F5" }}>User</span>
            </span>
            <span style={{ fontFamily: "Raleway", marginTop: 20 }}>
              <span style={{ color: "#4395F5" }}> Yay!</span> No more frustration due to disturbing calls. <span style={{ color: "#4395F5" }}>Nextcaller </span> has helped more <span style={{ fontWeight : 'bold' }}> XXXX  </span> people like you to solve similar problems.
            </span>
            <div className="hero__button">
              <button>See My Plans</button>
            </div>
          </div>
          <div className="hero__statImage" />
        </div>
        <div className="hero__faq">
          <div className="hero__faqtext">
            <h1>FAQs</h1>
            <span>
              This might help you to understand more about the app and its features.
            </span>
          </div>
          <div className="hero__faqSection">
            <FAQ id="1" question={"What is the purpose of Nextcaller?"} para={'Nextcaller is a call blocking app that helps you block colls like no other app. It allows you to set a custom time and duration for blocking colls of any type, spam, calls from family, necessary calls, etc. It heps you send auto replies to your callers to let them know that you are busy'} />
            <FAQ id="2" question={"Does the app contain ony in-app purchases?"} para={'No. once you claim you get all features for free'}  />
            <FAQ id="3" question={"Can we prevent blocking calls from trusted people?"} para={'You get a whitelist facility where you can mention the poeple whose calls wont be disrupted by nextcaller'}  />
          </div>
        </div>
        <div className="hero__nextFeature">
          <h1><span  style={{ color: "#4395F5",marginRight: 8 }}> Nextcaller {" "} </span><span></span> Ignore calls Smartly</h1>
          <div className="hero__nextFeatureCards">
           <div className="nc"><NextCard title={'Distraction-free environment'} para={'Prevent phone calls from distracting you during your working hours'} /></div> 
           <div className="nc"><NextCard title={'Ensure your responsibility'} para={'Ensure that your loved ones know you are busy doing hard work'} /></div> 
           <div className="nc"><NextCard title={'Reduce Stress'} para={'Increase your sense of control and tranquility by not stressing about too much disturbances'} /></div> 
           <div className="nc"><NextCard title={'Maintain your Professionalism'} para={'Make the meetings smooth by preventing distractions from the unwanted phone calls.'} /></div> 
           
          </div>
        </div>
        <div className="hero__testinomial">
          <div className="hero__testinomialText">
            <h1>User Testinomials</h1>
          </div>
          <div className="hero__testinomialCards">
            <TestinomialCard src='https://randomuser.me/api/portraits/men/94.jpg' name='Niraj Singh' stars='⭐️⭐️⭐️⭐️⭐️' test='This is the best DND & blocking app so far. It is very easy to use unlike similar apps that could not even perform at all. So I give my 5 stars to you.. Thanks.' />
            <TestinomialCard src='https://randomuser.me/api/portraits/men/1.jpg' name='John Doe' stars='⭐️⭐️⭐️⭐️' test='Works Great!' />
            <TestinomialCard src='https://randomuser.me/api/portraits/men/74.jpg' name='Walter jackson' stars='⭐️⭐️⭐️⭐️⭐️' test='Very nice app. User friendly'  />
            <TestinomialCard src='https://randomuser.me/api/portraits/men/61.jpg' name='Harry Bryant' stars='⭐️⭐️⭐️⭐️⭐️' test='"NextCaller saves me time and sanity. Best call-blocking app out there!"' />
          </div>
        </div>
        <div className="hero__plans">
          <div className="hero__plansText">
            <h1>What will your plan provide you?</h1>
              <div className="plansInfo">
                
                <p>1. Work mode to block calls automatically during your meetings </p>
                <p>2. Allow you to send auto replies to your callers</p>
                <p>3. Allow you to keep your whitelist whose calls won’t be disrupted by nextcaller</p>
                <p>4. Control the number of calls to be blocked set by you</p>
              </div>
          </div>
          {/* <div className="hero__nextcaller">
            <div>
              <h1>Nextcaller</h1>
              <span>
                Select your plan and proceed to access the various blocking
                modes for you to not get disturbed by any means during your busy
                time!
              </span>
            </div>
           
          </div> */}
          <div className="hero__prices">
              <h1>Choose your plan</h1>
              <div className="hero__priceCards">
              <div id="1" className="pc"><PayCard paymentLink={'https://buy.stripe.com/test_dR6dQRfSe6uo5Og000'} price={'Buy Now $25/-'} duration={month} width={200} height={22} /></div> 
              <div id="2" className="pc"><PayCard highlighted={highlighted} paymentLink={'https://buy.stripe.com/test_dR6dQRfSe6uo5Og000'} price={'Buy Now $25/-'} duration={year}  width={150} height={30}  /></div> 
              <div id="3" className="pc"><PayCard paymentLink={'https://buy.stripe.com/test_dR6dQRfSe6uo5Og000'} price={'Buy Now $25/-'} duration={sixMonth}  width={200} height={22}  /></div> 
                </div>
             
            </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
