import React from "react";
import "./Conclusion.css";
import { useNavigate } from "react-router-dom";
import ConclusionCard from "../Card/ConclusionCard";
// required parameter to fetch images

function Conclusion() {
  const navigate = useNavigate();
  return (
    <div className="conclusion__container">
      <div className="conclusion__logo" />
      <div className="conclusion__content">
        <div className="conclusion__text">Conclusion</div>
        <div className="conclusion__innerContent">
          <span>Based on the survey we have concluded that you are having</span>
          <span>
            {" "}
            these qualities in you and lets see how we can help you...
          </span>
        </div>
        <div className="conclusion__cards">
          <div className="conclusion__items">
            <ConclusionCard path={"/bowdown.png"} qualities={'Responsible'} />
          </div>
          <div className="conclusion__items">
            <ConclusionCard path={"/head.png"} qualities={'Dedicated'} />
          </div>
          <div className="conclusion__items">
            <ConclusionCard path={"/sad.png"}qualities={'Frustrated'} />
          </div>
          <div className="conclusion__items">
            <ConclusionCard path={"/what.png"}qualities={'Confused'} />
          </div>
        </div>
        <div className="conclusion__button">
          <button onClick={()=> navigate('/newsletter')} >See My Plans</button>
        </div>
      </div>
    </div>
  );
}

export default Conclusion;
