import React from "react";
import "./payCard.scss";

import check from "../../assets/check.png";

function PayCard(props) {

  return (
    <div className={`payCard__container ${props.highlighted ? 'highlighted' : ''}`}>
      <span className="dots" />
      <span className="ribbon1" />
      <span className="ribbon2" />
      {props.highlighted && <span className="crown"/>}
      <img
        src={props.duration}
        alt="not found"
        height={props.height}
        width={props.width}
        className="Premium__plan"
      />

      <div className="payCard__content">
        <p>
          <img src={check} alt="not ofund" width={15} height={15} /> Block Calls
          in multiple ways
        </p>
        <p>
          <img src={check} alt="not ofund" width={15} height={15} /> Auto
          Replies
        </p>
        <p>
          <img src={check} alt="not ofund" width={15} height={15} />{" "}
          Personalised Whitelist
        </p>
        <p>
          <img src={check} alt="not ofund" width={15} height={15} /> Set Timer
          call blocking
        </p>
      </div>

      <div className="payCard__buynow">
        <button>
          <a className="foo" href={props.paymentLink}>
            {props.price}
          </a>{" "}
        </button>
      </div>
    </div>
  );
}

export default PayCard;
