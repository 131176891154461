import React, { useState } from "react";
import "./Newsletter.css";
import { useNavigate } from "react-router-dom";

function Newsletter() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const clickHandler = () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email");
    } else {
      setError("");
      navigate("/hero");
    }
  };

  const validateEmail = (email) => {
    // Regular expression pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  return (
    <div className="newsletter__container">
      <div className="newsletter__logo" />
      <div className="newsletter__content">
        <div className="newsletter__text">We have prepared a plan for you! </div>
        <div className="newsletter__innerContent">
          <span>This is a plan for you prepared by us. Send us your mail so that we</span>
          <span> can send you the plans best for you!</span>
        </div>

        <div className="newsletter__input">
          <input
            type="text"
            id="email"
            className="hlw"
            placeholder="✉️ Your email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        {error && <div className="newsletter__error">{error}</div>}
        <div className="newsletter__button">
          <button onClick={clickHandler}>Continue</button>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
